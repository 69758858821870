import React from 'react'
import { Carousel } from "flowbite-react";

export default function Crousel() {
    return (
    <div className="lg:h-[80vh] md:h-[65vh] h-[45vh]" >
            <Carousel>
                <img src="images/c1.jpg"  className='w-full h-full' alt="..." />
                <img src="images/c2.jpg"  className='w-full h-full' alt="..." />
                <img src="images/c3.jpg" className='w-full h-full' alt="..." />
            </Carousel>
        </div>
    )
}
