import React from 'react'
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import Main from './pages/Main';
import HomePage from './pages/HomePage';
import Contact from './components/Contact';
import Plot from './pages/Plot';
import Flat from './pages/Flat';
import Villa from './pages/Vila';
import Privacy from './pages/Privacy';
import Candition from './pages/Candition';

const routers = createBrowserRouter(
  [
    {
      path: "/",
      element: <Main />,
      children: [
        {
          path: "/",
          element: <HomePage />
        }, {
          path: "/contact",
          element: <Contact />
        }, {
          path: "/plot",
          element: <Plot />
        }, {
          path: "/flat",
          element: <Flat />
        }, {
          path: "/vila",
          element: <Villa />
        }, {
          path: "/privacy-policy",
          element: <Privacy />
        }, {
          path: "/term-condition",
          element: <Candition />
        }
      ]
    }
  ]
)

export default function App() {

  return (
    <>
      <RouterProvider router={routers} />
    </>
  )
}
