import React from 'react'
import Ser from '../components/Ser'
import Crousel from '../components/Crousel'
import ProprityType from '../components/ProprityType'
import HelpPeople from '../components/HelpPeople'
import Listing from '../components/Listing'
import LockingProprity from '../components/LockingProprity'
import Numbring from '../components/Numbring'


export default function HomePage() {
  return (
    <div>
      <Crousel />
      <Ser />
      <Numbring />
      <ProprityType />
      <HelpPeople />
      <Listing />
      <LockingProprity />
     
    </div>
  )
}
