import React from 'react'
import { MdAddCall } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { FaLocationCrosshairs } from "react-icons/fa6";

export default function Contact() {
    return (
        <div>
            <div className='max-w-[1000px] my-3 m-auto grid md:grid-cols-3 grid-cols-1 ' style={{ boxShadow: " rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px" }}>
                <div className='p-5 mt-8 w-full sm:px-[50px] md:px-[20px]  '>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13999.648055387921!2d77.44310573338996!3d28.692278483166437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cf17448e07149%3A0x2ec279f3084ca048!2sSector%2023%2C%20Sanjay%20Nagar%2C%20Ghaziabad%2C%20Uttar%20Pradesh%20201002!5e0!3m2!1sen!2sin!4v1721885740976!5m2!1sen!2sin" className='w-full  md:h-[500px] h-[500px]'></iframe>

                </div>
                <div className='bg-white col-span-2 grid mt-8 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 pl-2'>
                    <div className='flex my-8 md:my-20'>
                        <div className='w-[100px] h-[100px] flex justify-center items-center text-blue-500 text-[32px] '><MdAddCall /></div>
                        <div>
                            <h2 className='p-2 font-bold'>Contact Us</h2>
                            <p className='pl-2'>Subodhnishad@gmail.coM
                            </p>
                            <p className='pl-2'>+91 9648908525</p>
                        </div>
                    </div>
                    <div className='flex  my-8 md:my-20'>
                        <div className='w-[100px] h-[100px] flex justify-center items-center text-blue-500 text-[32px] '><FaWhatsapp className='animate-ping' /></div>
                        <div>
                            <h2 className='p-2 font-bold hover:text-blue-500 cursor-pointer '>ChatNow</h2>
                            <p className='pl-2'>+91 9648908525</p>

                        </div>
                    </div>
                    <div >
                        <div className='flex  my-8 md:my-20'>
                            <div className='w-[200px] h-[100px] flex justify-center items-center text-blue-500 text-[32px] '><FaLocationCrosshairs /></div>
                            <div>
                                <h2 className='p-2 font-bold'>Address</h2>
                                <p className='pl-2'>Sanjay nagar, Sector 23,Ghaziabad,201002,UTTAR PRADESH  </p>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </div>
    )
}
