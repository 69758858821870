import React from 'react'

export default function Villa() {

    const listing = [
        {
            img: "images/v1.jpg"
        },
        {
           img: "images/v2.jpg"
        },
        {
           img: "images/p3.jpg"
        },
        {
           img: "images/v3.jpg"
        },
        {
           img: "images/p4.jpg"
        },
        {
            img: "images/p5.jpg"
        }
    ]
    return (
        <div className='w-full'>
            <div className='md:w-[80%] m-auto p-4'>
                <h1 className='text-[28px] py-4 text-center'>FEATURED LISTINGS</h1>
                <p className='pb-5 text-center'>Browse houses and flats for sale and to rent in your area</p>


                <div className='w-full grid md:grid-cols-3 gap-4 grid-cols-1 py-5'>
                    {
                        listing.map(
                            (d, i) => {
                                return (
                                    <div  key={i} className='border rounded-lg overflow-hidden mb-4'>
                                        <img src={d.img} alt=" " className='w-full min:h-[280px] md:h-[280px] pb-4' />
                                        <button className='w-full p-2 border bg-[#203644] text-white  hover:bg-[#201e1e]'>BOOk Now</button>
                                    </div>
                                )
                            }
                        )
                    }


                </div>
            </div>
        </div>
    )
}
