import React from 'react'
import Footer from '../components/Footer'
import { Outlet } from 'react-router-dom'
import Nav from '../components/Nav'

export default function Main() {
  return (
    <>
    <Nav/>
    <Outlet/>
    <Footer/>
    </>

  )
}
